import React, { useContext, useEffect } from 'react';
import { Header as AntHeader } from 'antd/lib/layout/layout';
import { Col, Row, Space, Typography, Image } from 'antd';
import { Account } from './Account';
import { useLocation } from 'react-router-dom';
import { SpaceContext } from '../contexts';
import glanceLogo from '../assets/glance-logo-red.png';

const { Title } = Typography;
export const Header: React.FC = (): JSX.Element => {
  const { setOnSpaces } = useContext(SpaceContext);
  // const [activeLink, setActiveLink] = useState('');
  const { pathname } = useLocation();
  const isSpaces = pathname.includes('/spaces');

  useEffect(() => {
    if (isSpaces) {
      setOnSpaces(isSpaces);
    }
  }, [isSpaces, setOnSpaces]);

  // const handleNavLinkClick = (e: React.MouseEvent<HTMLElement>): void => {
  //   const { target } = e;
  //   if (target) {
  //     setActiveLink((target as HTMLElement).id);
  //   }
  // };

  return (
    <AntHeader>
      <Row justify="space-between">
        <Col>
          <div className="header-menu">
            {/* <Link to="/"> */}
            <Title level={3} className="brand-logo" type="secondary">
              <Image height={30} src={glanceLogo} preview={false} />
            </Title>
            {/* TODO: Uncomment when adding navigation for topic mgmt */}
            {/* </Link> */}
          </div>
        </Col>
        <Col>
          <Space size="large">
            <Account />
          </Space>
        </Col>
      </Row>
    </AntHeader>
  );
};
