import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import './App.less';
import { Card, Empty, Layout, Spin, Typography } from 'antd';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Content } from 'antd/lib/layout/layout';
import { Header } from './components/Header';
import { useUserRolesQuery } from './hooks/user';
import { NO_ACCESS_DESCRIPTION } from './lang/en';
import {
  UserDataContext,
  SelectedPublisherDataContext,
  OTDStickerDataContext,
  SpaceContext,
  AppDataContext,
} from './contexts';
import {
  UserInfo,
  PublisherInfo,
  UploadOTDStickerResponseData,
  AppConfigData,
} from './types';
import { STORAGE_DATA } from './utils/storage';
import { SELECTED_PUBLISHER_KEY, INITIAL_APP_CONFIG_DATA } from './constants';
import { useGetOTDDetails } from './pages/Home/dataHooks';
import { useGetAppConfig } from './hooks/metadata';
const { Text } = Typography;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Home = lazy(() => import('./pages/Home'));
const PublisherSwitch = lazy(() => import('./pages/PublisherSwitch'));
const SpacesHome = lazy(() => import('./pages/Spaces-v1'));
const SpacesPublishing = lazy(() => import('./pages/Spaces/Publishing'));
const AppRouter = () => {
  return (
    <Suspense fallback={<Spin />}>
      <Routes>
        <Route path="/select-publisher" element={<PublisherSwitch />} />
        <Route path="/" element={<Home />} />
        <Route path="/spaces">
          <Route index={true} element={<SpacesPublishing />} />
          <Route path="*" element={<SpacesHome />} />
          {/* <Route path="story-details" element={<StoryDetails />} /> */}
        </Route>
      </Routes>
    </Suspense>
  );
};

const NoAccess = () => {
  return (
    <Card style={{ height: '100vh' }}>
      <Empty
        imageStyle={{
          height: 180,
        }}
        description={<Text type="warning">{NO_ACCESS_DESCRIPTION}</Text>}
      ></Empty>
    </Card>
  );
};

const MainSection = () => {
  const { userData: userDataInContext, setUserData } =
    useContext(UserDataContext);
  const { setOTDData } = useContext(OTDStickerDataContext);
  const { onSpaces, setOnSpaces } = useContext(SpaceContext);
  const { setAppConfigData } = useContext(AppDataContext);
  const { isLoading, data } = useUserRolesQuery();
  const { data: otdSticker } = useGetOTDDetails();
  const { data: configData } = useGetAppConfig();
  const navigate = useNavigate();
  const { setSelectedPublisherData } = useContext(SelectedPublisherDataContext);
  useEffect(() => {
    setUserData(data?.userData || null);
    if (data) {
      const publisherInfo = STORAGE_DATA?.get(SELECTED_PUBLISHER_KEY);
      publisherInfo && setSelectedPublisherData(publisherInfo);
      !publisherInfo && navigate('/select-publisher', { replace: true });
    }
  }, [data, setUserData]);

  useEffect(() => {
    if (otdSticker) {
      setOTDData(otdSticker);
    }
  }, [otdSticker, setOTDData]);

  useEffect(() => {
    if (onSpaces) {
      setOnSpaces(onSpaces);
    }
  }, [onSpaces, setOnSpaces]);

  useEffect(() => {
    if (configData) {
      setAppConfigData(configData.data);
    }
  }, [configData, setAppConfigData]);

  const getContent = () => {
    if (isLoading) {
      return <Spin />;
    }
    if (data) {
      const { userData } = data;
      const hasRoles = userData.users && userData.users.length > 0;
      if (hasRoles) {
        return userDataInContext ? <AppRouter /> : <></>;
      }
    }
    // api will give error in case no user-role association is found
    return <NoAccess />;
  };

  return (
    <Content className="site-layout">
      <div className="site-layout-background">
        <>{getContent()}</>
      </div>
    </Content>
  );
};

function App(): JSX.Element {
  const [userData, setUserData] = useState<UserInfo>(null);
  const [appConfigData, setAppConfigData] = useState<AppConfigData>(
    INITIAL_APP_CONFIG_DATA,
  );
  const [selectedPublisherData, setSelectedPublisherData] =
    useState<PublisherInfo>({
      name: '',
      id: '',
    });
  const [OTDData, setOTDData] = useState<UploadOTDStickerResponseData>({
    isEnabled: false,
    isActive: false,
    imageId: '',
    imageUrl: '',
  });
  const [onSpaces, setOnSpaces] = useState(false);
  return (
    <QueryClientProvider client={queryClient}>
      <UserDataContext.Provider value={{ userData, setUserData }}>
        <AppDataContext.Provider value={{ appConfigData, setAppConfigData }}>
          <SelectedPublisherDataContext.Provider
            value={{ selectedPublisherData, setSelectedPublisherData }}
          >
            <OTDStickerDataContext.Provider value={{ OTDData, setOTDData }}>
              <SpaceContext.Provider value={{ onSpaces, setOnSpaces }}>
                <Router>
                  <Layout>
                    <Header />
                    <MainSection />
                  </Layout>
                </Router>
              </SpaceContext.Provider>
            </OTDStickerDataContext.Provider>
          </SelectedPublisherDataContext.Provider>
        </AppDataContext.Provider>
      </UserDataContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
